import { createBrowserRouter } from 'react-router-dom';
import React, {Suspense} from "react";
import GuestLayout from './pages/layouts/GuestLayout';
import CircularProgress from "@mui/material/CircularProgress";
//import Rentals from "./pages/superadmin/Rentals";



const ProtectedLayout = React.lazy(()=>import('./pages/layouts/ProtectedLayout'));
//import Credential from "./pages/clients/Credential";
//import OrderSuccessful from "./pages/clients/OrderSuccess";
/*

const Suppliers = React.lazy(()=>import('./pages/clients/Suppliers'));
const OrderSuccessful = React.lazy(()=>import('./pages/clients/OrderSuccess'));

const ForAds = React.lazy(()=>import('./pages/clients/ForAds'));

const Place = React.lazy(()=>import('./pages/clients/Place'));
const VehicleCardClient = React.lazy(()=>import('./pages/clients/VehicleCard'));
const Profile = React.lazy(()=>import('./pages/clients/Profile'));
*/

const Rentals = React.lazy(()=>import('./pages/superadmin/Rentals'));
const AIAssistant = React.lazy(()=>import('./pages/superadmin/AIAssistant'));
//const Place = React.lazy(()=>import('./pages/clients-to-delete/Place'));
const Main = React.lazy(()=>import('./pages/Main'));




//const ProtectedLayout = React.lazy(()=>import('./pages/layouts/ProtectedLayout'));

//const GuestLayout = React.lazy(()=>import('./pages/layouts/GuestLayout'));
//import App from './App'
/*const Contacts = React.lazy(()=>import('./pages/clients/Contacts'));*/
/*import BusinessAbout from "./pages/business/BusinessAbout";*/
/*const BusinessAbout = React.lazy(()=>import('./pages/business/BusinessAbout'));*/
/*import Dashboard from "./pages/business/Dashboard";*/
const Dashboard = React.lazy(()=>import('./pages/business/Dashboard'));
/*import Worktime from "./pages/business/Worktime";*/
const Worktime = React.lazy(()=>import('./pages/business/Worktime'));
/*import RentRules from "./pages/business/RentRules";*/
const RentRules = React.lazy(()=>import('./pages/business/RentRules'));
/*import Seasons from "./pages/business/Seasons";*/
const Seasons = React.lazy(()=>import('./pages/business/Seasons'));
/*import Addons from "./pages/business/Addons";*/
const Addons = React.lazy(()=>import('./pages/business/Addons'));
/*import BankInformation from "./pages/business/BankInformation";*/
const BankInformation = React.lazy(()=>import('./pages/business/BankInformation'));
/*import Inbox from "./pages/business/Inbox";*/
const Inbox = React.lazy(()=>import('./pages/business/Inbox'));
/*import Orders from "./pages/business/Orders";*/
const Orders = React.lazy(()=>import('./pages/business/Orders'));
const ExchangeOrders = React.lazy(()=>import('./pages/business/ExchangeOrders'));
/*import Calendar from "./pages/business/Calendar";*/
const Calendar = React.lazy(()=>import('./pages/business/Calendar'));
/*import Garage from "./pages/business/Garage";*/
const Garage = React.lazy(()=>import('./pages/business/Garage'));
/*import Motorcycles from "./pages/business/Motorcycles";*/
const Motorcycles = React.lazy(()=>import('./pages/business/Motorcycles'));
/*import Reviews from "./pages/business/Reviews";*/
const Reviews = React.lazy(()=>import('./pages/business/Reviews'));
/*import Messages from "./pages/clients/Messages";*/
/*const Messages = React.lazy(()=>import('./pages/clients/Messages'));*/
/*import Support from "./pages/business/Support";*/
const Support = React.lazy(()=>import('./pages/business/Support'));
/*import Tutorial from "./pages/business/Tutorial";*/
const Tutorial = React.lazy(()=>import('./pages/business/Tutorial'));

/*import Garages from "./pages/business/Garages";*/
const Garages = React.lazy(()=>import('./pages/business/Garages'));
const Insurances = React.lazy(()=>import('./pages/business/Insurances'));

/*import MessagesBusiness from "./pages/business/Messages";*/
const MessagesBusiness = React.lazy(()=>import('./pages/business/Messages'));
const PlacesSeo = React.lazy(()=>import('./pages/superadmin/PlacesSeo'));

/*import AddNewMotorcycle  from "./pages/business/service/AddNewMotorcycle";*/
const AddNewMotorcycle = React.lazy(()=>import('./pages/business/service/AddNewMotorcycle'));

/*
const SearchResult = React.lazy(()=>import('./pages/clients/SearchResult'));

/!*import ClientOrders from "./pages/clients/Orders";*!/
const ClientOrders = React.lazy(()=>import('./pages/clients/Orders'));
/!*import OrderCard from "./pages/clients/OrderCard";*!/
const OrderCard = React.lazy(()=>import('./pages/clients/OrderCard'));


const Article = React.lazy(()=>import('./pages/clients/Article'));

/!*import SuperadminLayout from "./pages/layouts/SuperadminLayout";*!/*/
const SuperadminLayout = React.lazy(()=>import('./pages/layouts/SuperadminLayout'));
/*import ResetEmailPassword from "./pages/clients/ResetEmailPassword";*/
/*const ResetEmailPassword = React.lazy(()=>import('./pages/clients/ResetEmailPassword'));*/



const ShopCard = React.lazy(()=>import('./pages/business/service/ShopCard'));

const VehicleCard = React.lazy(()=>import('./pages/business/service/VehicleCard'));

const Texts = React.lazy(()=>import('./pages/superadmin/Texts'));
const MessengerTemplates = React.lazy(()=>import('./pages/superadmin/MessengerTemplates'));

const BusinessLayout = React.lazy(()=>import('./pages/layouts/BusinessLayout'));
const RegisterForm = React.lazy(()=>import('./pages/RegisterForm'));

const fallback = <div className={'page-load-spinner'}><CircularProgress  sx={{color: '#ff1b2d', maxWidth: 100, maxHeight: 100}} /></div>

const router = createBrowserRouter([
    {
        path: '/',
        element: <Suspense fallback={fallback}><GuestLayout /></Suspense>,
        children: [
            {path: "/", element: <Suspense fallback={fallback}><Main /></Suspense>},
        ],

    },
    {
        path: '/',
        element: <Suspense fallback={fallback}><ProtectedLayout /></Suspense>,
        children: [
            /*{
                path: "/",
                element: <ProtectedLayout/>,
                children : [
                    {path: "/superadmin/texts/", element: <Texts />},
                    {path: "/superadmin/templates/messenger-templates", element: <MessengerTemplates />},
                ]
            },*/
            {   path: "/",
                element: <BusinessLayout />,
                children : [
                    {path: '/register', element: <Suspense fallback={<div>Loading...</div>}><RegisterForm /></Suspense>},
                    {path: '/dashboard', element: <Suspense fallback={<div>Loading...</div>}><Dashboard /></Suspense>},
                    {path: "/config/shop", element: <Suspense fallback={<div>Loading...</div>}><ShopCard /></Suspense>},
                    {path: "/config/shop/:param", element: <Suspense fallback={<div>Loading...</div>}><ShopCard /></Suspense>},
                    {path: "/config/garages", element: <Suspense fallback={<div>Loading...</div>}><Garages /></Suspense>},
                    {path: "/config/insurances", element: <Suspense fallback={<div>Loading...</div>}><Insurances /></Suspense>},
                    {path: "/config/rent-rules", element: <Suspense fallback={<div>Loading...</div>}><RentRules /></Suspense>},
                    {path: "/config/seasons", element: <Suspense fallback={<div>Loading...</div>}><Seasons /></Suspense>},
                    {path: "/config/addons", element: <Suspense fallback={<div>Loading...</div>}><Addons /></Suspense>},
                    {path: "/config/bank-information", element: <Suspense fallback={<div>Loading...</div>}><BankInformation /></Suspense>},
                    {path: "/orders/:filter", element: <Suspense fallback={<div>Loading...</div>}><Orders /></Suspense>},
                    {path: "/exchange-orders", element: <Suspense fallback={<div>Loading...</div>}><ExchangeOrders /></Suspense>},
                    {path: "/garage", element: <Suspense fallback={<div>Loading...</div>}><Garage /></Suspense>},
                    {path: "/reviews", element: <Suspense fallback={<div>Loading...</div>}><Reviews /></Suspense>},
                    {path: "/messages", element: <Suspense fallback={<div>Loading...</div>}><MessagesBusiness /></Suspense>},
                    {path: "garage/:vehicleType/:garage_id/:id", element: <Suspense fallback={<div>Loading...</div>}><VehicleCard /></Suspense>},
                    /*{path: "garage/add-new-motorcycle", element: <Suspense fallback={<div>Loading...</div>}><AddNewMotorcycle /></Suspense>},*/


                    /*{path: "/business/work/reports", element: <Report />},*/
                    /*{path: "/business/support", element: <Suspense fallback={<div>Loading...</div>}><Support /></Suspense>},*/
                    /*{path: "/business/tutorial", element: <Suspense fallback={<div>Loading...</div>}><Tutorial /></Suspense>},*/
                    /*{path: "/business/work/motorcycles", element: <Suspense fallback={<div>Loading...</div>}><Motorcycles /></Suspense>},*/

                ],

            },
            {
                path: "/superadmin/",
                element: <SuperadminLayout/>,
                children : [
                    {path: "/superadmin/texts/", element: <Suspense fallback={<div>Loading...</div>}><Texts /></Suspense>},
                    {path: "/superadmin/places-seo/", element: <Suspense fallback={<div>Loading...</div>}><PlacesSeo /></Suspense>},
                    {path: "/superadmin/templates/messenger-templates", element: <Suspense fallback={<div>Loading...</div>}><MessengerTemplates /></Suspense>},
                    {path: "/superadmin/rentals/", element: <Suspense fallback={<div>Loading...</div>}><Rentals /></Suspense>},
                    {path: "/superadmin/aiassistant/", element: <Suspense fallback={<div>Loading...</div>}><AIAssistant /></Suspense>},
                    ]
            }


        ],
    },
]);

export default router;
